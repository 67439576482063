<template>
  <Page class="page-categories page-edit-category">
    <template #title>Modifica categoria</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'categories.show' }">Annulla modifica</router-link>
    </template>

    <template #default>
      <CategoryForm :feedback.sync="feedback" :category="categoryToEdit" @submitForm="submit" edit />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import CategoryForm from '@/views/components/Form/CategoryForm.vue';

export default {
  components: {
    Page,
    CategoryForm,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    categoryToEdit: {},
    feedback: null,
  }),
  watch: {
    category (newValue) {
      this.updateCategoryToEdit(newValue);
    },
  },
  methods: {
    updateCategoryToEdit (category) {
      this.categoryToEdit = {
        ...category,
        ...this.categoryToEdit,
      };
    },
    submit (categoryToEdit) {
      const category = { ...categoryToEdit };

      this.$api.updateCategory(category.id, category)
        .then(res => {
          this.feedback = true;
          this.$emit('update:category', res.data);
          this.categoryToEdit = {};
          this.$router.push({ name: 'categories.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateCategoryToEdit(this.category);
  },
};

</script>
